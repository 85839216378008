import React from "react"
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default function Page(props) {
	return (
		<Navbar bg="dark" variant="dark" sticky="bottom">
			<Nav className="mr-auto">
				<Nav.Link href="//spaceleaves.com/legal/contact" target="_blank">Impressum</Nav.Link>
				<Nav.Link href="//spaceleaves.com/legal/privacy" target="_blank">Datenschutzerklärung</Nav.Link>
			</Nav>
			<Nav>
				<Navbar.Text className="align-center d-none d-md-inline-block">Diese Webseite ist Open Source:</Navbar.Text>
			</Nav><Nav>
				<Navbar.Brand>
					<Nav.Link href="https://codeberg.org/eventbike/StreamSpace" target="_blank">
						<img
							alt=""
							src="/find-it-on-codeberg.svg"
							height="35"
							className="d-none d-sm-inline-block align-top"
						/>{' '}
					</Nav.Link>
				</Navbar.Brand>
			</Nav>
		</Navbar>
	);
}
