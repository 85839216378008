import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default function Navigation(props) {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
					}
				}
			}
		`
	)
	
	return (
		<>
		<Navbar bg="dark" variant="dark" sticky="top">
			<Navbar.Brand>
				<Link to="/" className="nav-link">
				<img
					alt=""
					src="https://spaceleaves.com/branding1.svgz"
					width="30"
					height="30"
					className="d-inline-block align-top"
					/>{' '}
					</Link>
				</Navbar.Brand>
				<Nav className="mr-auto">
					<Link to="/" className="nav-link">{data.site.siteMetadata.title}</Link>
					<Navbar.Text className="d-none d-md-block">
						- {data.site.siteMetadata.description}
					</Navbar.Text>
					<a href="//eventbikezero.de" target="_blank" className="nav-link">Über das eventbike</a>
				</Nav>
				<Nav  className="d-none d-sm-block">
					<Navbar.Text>
						{props.event_title}
					</Navbar.Text>
				</Nav>
			</Navbar>
		</>
	);
}
