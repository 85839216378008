import React from "react"

import Navigation from "./navigation"
import Footer from "./footer"
import * as pageStyle from "./page.module.css"

export default function Page(props) {
	return (
		<div className={pageStyle.page}>
			<Navigation event_title={props.title}/>
				<br />
				{props.children}
			<Footer />
		</div>
	);
}
